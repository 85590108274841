export default {
    "sidebar.dashboard": "Dashboard",
    "sidebar.kunto":"kunto",
    "sidebar.userlist":"Benutzerliste",
    "sidebar.log":"Api-Log",
    
    "sidebar.package": "Paket",
    "sidebar.notifications": "Protokoll",
    "sidebar.statistics": "Statistik",
    "sidebar.developers": "Entwickler",
    "sidebar.apiKeys": "API Schlüssel",
    "sidebar.documentation": "Dokumentation",
    "sidebar.search": "Suche",
    "sidebar.customers": "Kunden",
    "sidebar.products": "Artikel & Produkte",
    "sidebar.categories": "Kategorien",
    "sidebar.vehicle": "Fahrzeug",
    "sidebar.invoices": "Bestellungen",
    "sidebar.users": "Benutzerliste",
    "sidebar.companies": "Unternehmen",
    "sidebar.companiesList": "Unternehmensliste",
    "widgets.api_log": "API-LOG",
    "sidebar.api_log": "API-LOG",
    "sidebar.export": "Export",
    "sidebar.crm_settings": "CRM-Einstellungen",
    "sidebar.persons": "Personensuche",
    "sidebar.personsList":"Suchliste",
    "sidebar.shop": "Shop",
    "widgets.welcome": "Willkommen zurück",
    "widgets.titleError": "Bitte geben Sie den Titel ein.",
    "widgets.priceNetError": "Bitte geben Sie die Preis (Netto) ein.",
    "widgets.categoryError": "Bitte geben Sie die Kategorie ein.",
    "widgets.products": "Produkte",
    "widgets.company_list": "Unternehmensliste",
    "widgets.title": "Titel",
    "widgets.category": "Kategorie",
    "widgets.productDescription": "Produktbeschreibung",
    "widgets.image": "Bild",
    "widgets.price_net": "Preis (Netto)",
    "widgets.price_brut": "Preis Brutto",
    "widgets.tva": "UST.",
    "widgets.discount": "Rabatt",
    "widgets.submit": "Speichern",
    "widgets.annuler": "Abbrechen",
    "widgets.companies": "Unternehmen",
    "widgets.customer": "Kunde",
    "widgets.invoices": "Rechnungen",
    "widgets.invoice": "Rechnung",
    "widgets.users": "Benutzer",
    "widgets.Bankdaten": "Bankdaten",
    "widgets.Api": "API",
    "widgets.search": "Suche ...",
    "widgets.company": "Unternehmen",
    "widgets.manage_your_account": "Konto verwalten",
    "widgets.sign_out": "Logout",
    "widgets.date": "Datum",
    "widgets.status": "Status",
    "widgets.requests": "Abfrage",
    "widgets.ContactPerson": "Kontaktperson",
    "widgets.customerList": "Kundenliste",
    "widgets.customers": "Kunden",
    "widgets.customerDetails": "Kundendetails",
    "component.email": "Email",
    "component.firstName": "Vorname",
    "component.lastName": "Nachname",
    "component.salutation": "Anrede",
    "component.title": "Titel",
    "component.general": "Allgemein",
    "component.account": "Konto",
    "component.password": "Passwort",
    "component.update": "Aktualisieren",
    "component.settings": "Einstellungen",
    "component.newPassword": "Neues Passwort",
    "component.retypePassword": "Passwort bestätigen",
    "component.passwordError": "Ihr Passwort muss mindestens 6 Zeichen lang sein",
    "component.passwordMatchError": "Bitte bestätigen Sie Ihre Passwort",
    "component.invoice": "Bestellungen",
    "component.addUser": "Benutzer hinzufügen ",
    "component.edit": "bearbeiten",
    "component.delete": "Entfernen",
    "component.logo": "Logo",
    "component.company_name": "Firmenname",
    "component.phone_number": "Festnetz",
    "component.mobile_number": "Mobil",
    "component.fax": "Fax",
    "component.house_number": "Hausnummer",
    "component.street": "Strasse",
    "component.zip_code": "PLZ",
    "component.city": "Statd/Ort",
    "component.country": "Land",
    "component.bank_name": "Bankname",
    "component.bank_account": "Kontonummer",
    "component.iban": "IBAN",
    "component.bic": "BIC",
    "component.submit": "Speichern",
    "component.username": "Benutzername",
    "component.key": "API-Schlüssel",
    "component.invoice_lower_text": "Rechnungstext (unter der Rechnung)",
    "component.invoice_upper_text": "Rechnungstext (über der Rechnung)",
    "component.tva": "UST.",
    "component.website": "Website",
    "component.customer": "Kunde",
    "component.deleteCustomeralert": "Möchten Sie diesen Kunden wirklich endgültig löschen?",
    "component.deleteUseralert": "Möchten Sie diesen Benutzer wirklich endgültig löschen?",
    "component.contactInformation": "KONTAKT",
    "component.deleteMessage": "Möchten Sie diesen wirklich endgültig löschen?",
    "component.address": "ADRESSE",
    "component.yes": "Ja",
    "component.no": "Nein",
    "component.ustid": "UST-ID-Nr.",
    "component.registernumber": "Registernummer",
    "component.invoiceAddress": "Alternative Rechnungsadresse",
    "component.paymentMethod": "Zahlungsart",
    "component.paymentMethodText": "SEPA Lastschriftverfahren",
    "component.products_and_prices": "Produkte und Preise",
    "component.add": "Hinzufügen",
    "component.sender_number": "Absender(Nummer)",
    "component.sendInvoiceMessage": "Möchten Sie eine E-Mail senden?",
    "component.save_draft": "Entwurf",
    "widgets.updateCustomer": "BEARBEITEN",
    "widgets.orders": "Bestellungen",
    "widgets.new_customers": "Neuzulassungen",
    "widgets.document_austria": "Produkte: ÖSTERREICH",
    "widgets.document_solivenia": "Produkte: SLOWENIEN",
    "widgets.number_of_customers": "Anzahl der Kunden",
    "widgets.number_of_guest": "Anzahl der Guest",
    "widgets.document_order_number": "Anzahl der Bestellungen : Dokument",
    "widgets.person_order_number": "Anzahl der Bestellungen : Person",
    "widgets.unity": "Maßeinheiten",
    "widgets.daily_statistics": "Tägliche Statistiken"


}